<template>
    <section class="flex flex-row justify-between items-start w-full pt-40 px-10 bg-white">
        <div class="w-4/5 flex flex-col px-10 bg-white">
            <div class="w-full flex justify-start items-center gap-5 h-16">
                <button
                    v-for="(term, index) in terms"
                    :key="index"
                    class="text-lg font-bold text-center border rounded-md py-3 px-8 z-10"
                    :class="selected == term.title ? 'bg-NeonBlue text-white  border-NeonBlue' : 'bg-white text-slate-700 border-gray-300'"
                    @click="selected = term.title"
                >
                    {{ term.title }}
                </button>
            </div>
            <div class="w-full py-4 text-left bg-white">
                <p class="bg-[#fff]" v-html="text"></p>
            </div>
        </div>
        <aside class="w-1/5 shadow-card rounded-sm bg-white mt-16 py-4">
            <ul class="list-none pl-8">
                <li class="font-normal text-base text-left my-3" v-for="(item, index) in items" :key="index"><font-awesome-icon :icon="['fas', 'caret-right']" /> {{ item }}</li>
            </ul>
        </aside>
    </section>
</template>

<script>
import { disclaimer, privacy, cookie, customer } from "@/store/terms.js";
export default {
    name: "PolicyTerms",
    data() {
        return {
            selected: "Disclaimer",
            // items:[],
            terms: [
                { title: "Disclaimer", items: ["Website disclaimer", "External links disclaimer", "Professional disclaimer", "Affiliates disclaimer"] },
                {
                    title: "Privacy notice",
                    items: [
                        "what information do we collect?",
                        "how do we process your information?",
                        "when and with whom do we share your personal information?",
                        "do we use cookies and other tracking technologies?",
                        "how do we handle your social logins?",
                        "is your information transferred internationally?",
                        "how long do we keep your information?",
                        "do we collect information from minors?",
                        "what are your privacy rights?",
                        "controls for do-not-track features",
                        "do we make updates to this notice?",
                        "how can you contact us about this notice?",
                        "how can you review, update, or delete the data we collect from you?",
                    ],
                },
                {
                    title: "Cookie policy",
                    items: [
                        "What are cookies?",
                        "Why do we use cookies?",
                        "How can I control cookies?",
                        "How can I control cookies on my browser?",
                        "What about other tracking technologies, like web beacons?",
                        "Do you use Flash cookies or Local Shared Objects?",
                        "Do you serve targeted advertising?",
                        "How often will you update this, Cookie Policy?",
                        "Where can I get further information?",
                    ],
                },
                {
                    title: "Customer & candidate terms of use ",
                    items: [
                        "Grant of access and use",
                        "The Services",
                        "Your Account",
                        "User rights and responsibilities",
                        "Account suspension",
                        "Maintenance and Downtime, Modifications",
                        "Free Plan and Beta Products",
                        "Fees and Payment Terms",
                        "Intellectual Property and Data",
                        "Representations, Warranties, and Disclaimer",
                        "Indemniﬁcation",
                        "Limitation of Liability",
                        "Confidentiality",
                        "Term and termination",
                        "Amendments",
                        "Miscellaneous",
                        "Governing law",
                    ],
                },
            ],
        };
    },
    computed: {
        items() {
            return this.terms.filter((term) => term.title === this.selected)[0].items;
        },
        text() {
            const items = { disclaimer: disclaimer, privacy: privacy, cookie: cookie, customer: customer };
            const selectedItem = this.terms
                .filter((term) => term.title === this.selected)[0]
                .title.split(" ")[0]
                .toLocaleLowerCase();
            // console.log('selected ..........',selectedItem, items[selectedItem])
            return items[selectedItem];
        },
    },
    mounted() {
        // import { disclaimer } from '../store/terms.js'
    },
};
</script>

<style lang="scss" scoped></style>

<template>
    <TalentEmail :showEmail="showEmail" :toggleEmail="toggleEmail" />
    <div class="new-landing-page">
        <logIn class="loding-modal" :show="login_pop" :toggleModal="toggleModal" />
        <section class="hero-container">
            <div class="description">
                <img loading="lazy" decoding="async" class="wordpress-icon hero-wordpress" src="@/assets/wordpress-icon.png" alt="" />

                <div class="left-side">
                    <div class="hero-title">
                        {{ $t("Welcome to your next growth opportunity.") }}
                    </div>
                    <div class="hero-sub-title">
                        {{ $t("Get connected with full time") }}
                    </div>
                    <button @click="toggleEmail">{{ $t("APPLY AS A TALENT") }}</button>
                </div>
                <div class="right-side">
                    <div class="right-container">
                        <div class="hero-dots">
                            <BlueDots />
                        </div>
                        <div class="hero-dots-two">
                            <BlueDots />
                        </div>

                        <div class="black-man">
                            <img loading="lazy" decoding="async" src="@/assets/abiola.png" alt="" />
                        </div>
                        <div class="experience-img">
                            <img loading="lazy" decoding="async" src="@/assets/experience.png" alt="" />
                        </div>
                        <img loading="lazy" decoding="async" class="figma" src="@/assets/figma.png" alt="" />
                        <img loading="lazy" decoding="async" class="java" src="@/assets/java.png" alt="" />
                        <img loading="lazy" decoding="async" class="xd" src="@/assets/xd.png" alt="" />
                        <img loading="lazy" decoding="async" class="html" src="@/assets/html-5.png" alt="" />
                        <div class="skills-conatiner" src="" alt=""></div>
                    </div>
                </div>
            </div>
            <div class="header-container">
                <div class="hero-title hero-title-second">
                    {{ $t("We open you up to a tool of possibilities.") }}
                </div>
                <div class="hero-sub-title hero-sub-title-second">
                    {{ $t("Erase completely the stress") }}
                </div>
            </div>
        </section>
        <section class="features">
            <div class="feature-card">
                <div class="feature-img">
                    <img loading="lazy" decoding="async" src="@/assets/pc-user.png" alt="" />
                </div>
                <img loading="lazy" decoding="async" class="web-icon" src="@/assets/section-1.png" alt="" />
                <div class="titles">
                    <div class="title">{{ $t("Pass Test") }}</div>
                    <div class="sub-title">
                        {{ $t("Pass our test") }}
                    </div>
                </div>
            </div>
            <div class="feature-card card-two">
                <div class="feature-img">
                    <img loading="lazy" decoding="async" src="@/assets/2-guys.png" alt="" />
                </div>
                <img loading="lazy" decoding="async" class="facebook-icon" src="@/assets/facebook.png" alt="" />
                <img loading="lazy" decoding="async" class="google-icon" src="@/assets/google.png" alt="" />
                <img loading="lazy" decoding="async" class="paypal-icon" src="@/assets/paypal.png" alt="" />
                <div class="titles">
                    <div class="title">{{ $t("Join Community") }}</div>
                    <div class="sub-title">
                        {{ $t("Improvement? No Problem!") }}
                    </div>
                </div>
            </div>
            <div class="feature-card card-three">
                <div class="feature-img">
                    <img loading="lazy" decoding="async" src="@/assets/black-woman.png" alt="" />
                </div>
                <div class="pc-girls">
                    <img loading="lazy" decoding="async" src="@/assets/pc-girls.png" alt="" />
                </div>
                <div class="glass-guy">
                    <img loading="lazy" decoding="async" src="@/assets/glass-guy.png" alt="" />
                </div>
                <div class="titles">
                    <div class="title">{{ $t("Get Hired") }}</div>
                    <div class="sub-title">
                        {{ $t("Are one off gigs your preference?") }}
                    </div>
                </div>
            </div>
        </section>
        <section class="steps-section">
            <div class="blue-container">
                <div class="white-circle-one"></div>
                <div class="white-circle-two"></div>
                <div class="white-circle-three"></div>
                <div class="white-circle-four"></div>
                <img loading="lazy" decoding="async" class="wordpress-icon" src="@/assets/wordpress-icon.png" alt="" />
                <div class="white-dots">
                    <WhiteDots />
                </div>
                <div class="first-container">
                    <div class="first-img">
                        <img loading="lazy" decoding="async" src="@/assets/smiling-woman.png" alt="" />
                    </div>
                    <div class="first-content">
                        <div class="title white">{{ $t("A One-Stop Platform") }}</div>
                        <div class="sub-title white">
                            {{ $t("You connect with GO PLATFORM") }}
                        </div>
                        <button @click="toggleEmail">{{ $t("Get Started") }}</button>
                    </div>
                </div>
                <div class="second-container">
                    <div class="first-content">
                        <div class="title white">
                            {{ $t("Talent Centric Process, Putting you FIRST!") }}
                        </div>
                        <div class="sub-title white">
                            {{ $t("GO PLATFORM collaborates with companies") }}
                        </div>
                        <button @click="toggleEmail">{{ $t("Get Started") }}</button>
                    </div>
                    <div class="first-img">
                        <img loading="lazy" decoding="async" src="@/assets/date.png" alt="" />
                    </div>
                </div>
                <div class="white-dots-two">
                    <WhiteDots />
                </div>
            </div>
        </section>
        <section class="talent-section">
            <img loading="lazy" decoding="async" class="guy-one" src="@/assets/guy-one.png" alt="" />
            <img loading="lazy" decoding="async" class="guy-two" src="@/assets/guy-two.png" alt="" />
            <img loading="lazy" decoding="async" class="guy-three" src="@/assets/guy-three.png" alt="" />
            <img loading="lazy" decoding="async" class="guy-four" src="@/assets/guy-four.png" alt="" />
            <img loading="lazy" decoding="async" class="guy-five" src="@/assets/guy-five.png" alt="" />
            <img loading="lazy" decoding="async" class="guy-six" src="@/assets/guy-six.png" alt="" />

            <div class="blue-dots">
                <BlueDots />
            </div>
            <div class="blue-dots-two">
                <BlueDots />
            </div>
            <div class="hero-title hero-title-second title-talent">
                {{ $t("Trusted by Talents across AFRICA!") }}
            </div>
            <div class="stack">
                <div class="first-panel">
                    {{ $t("“The consultants that reached out to me”") }}
                </div>
                <div class="second-panel"></div>
                <div class="third-panel"></div>
            </div>
        </section>
        <section class="last-section">
            <div class="hero-title hero-title-second">
                {{ $t("Get matched to your dream opportunity") }}
            </div>
            <div class="hero-sub-title hero-sub-title-second last-title">
                {{ $t("Save time and Focus your energy") }}
            </div>
            <button @click="toggleEmail">{{ $t("APPLY AS A TALENT") }}</button>
            <div class="students">
                <img loading="lazy" decoding="async" src="@/assets/students.png" alt="" />
            </div>
            <div class="yellow-circle-one"></div>
            <div class="yellow-circle-two"></div>
            <div class="yellow-circle-three"></div>
            <div class="dots-right">
                <BlueDots />
            </div>
            <div class="dots-left">
                <BlueDots />
            </div>
        </section>
    </div>
</template>

<script>
import WhiteDots from "@/components/WhiteDots.vue";
import BlueDots from "@/components/BlueDots.vue";
import logIn from "@/components/logIn.vue";
import TalentEmail from "@/components/TalentEmail.vue";
export default {
    name: "landingPage",
    components: { WhiteDots, BlueDots, logIn, TalentEmail },
    data() {
        return {
            login_pop: false,
            showEmail: false,
        };
    },
    methods: {
        toggleModal() {
            this.login_pop = !this.login_pop;
        },
        toggleEmail() {
            this.showEmail = !this.showEmail;
        },
    },
};
</script>

<style scoped>
.new-landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 0px;
    margin-bottom: -5%;
}

.loding-modal {
    z-index: 20;
}

.hero-container {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    gap: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(180deg, #e5efff 0%, rgba(229, 239, 255, 0.26) 83.7%, rgba(229, 239, 255, 0) 100%); */
    background: #fff;
}

button {
    width: 200px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #2196f3;

    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    cursor: pointer;
}

button:hover {
    opacity: 0.85;
}

.description {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    width: 70%;
    margin-top: 100px;
    height: 100%;
}

.left-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    width: 65%;
    margin-top: 10%;
}

.right-side {
    width: 35%;

    height: 400px;
}

.right-container {
    position: relative;
    width: 400px;
    height: 100%;
}

.hero-title {
    color: #343637;

    /* Heading/2 */
    font-family: Roboto;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    text-align: start;
    /* 111.765% */
}

.header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.hero-title-second {
    color: #343637;
    text-align: center;
    font-size: 44px;
    font-weight: 600;

    width: 60%;
}

.hero-sub-title {
    color: #94a2b3;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    text-align: start;
    /* 138.889% */
}

.title {
    color: #272d4e;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
}

.sub-title {
    color: #94a2b3;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    overflow: wrap;

    text-align: start;
}

.steps-section .sub-title {
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    overflow: wrap;

    text-align: start;
}

.hero-sub-title-second {
    text-align: center;
    width: 60%;
}

.black-man {
    width: 200px;
    height: 250px;
    overflow: hidden;
    border-radius: 10px;
    position: absolute;
    top: 20%;
    right: 28%;
    box-shadow:
        0 8px 12px rgba(0, 0, 0, 0.15),
        0 3px 6px rgba(0, 0, 0, 0.1);
    /* Slightly larger box shadow */
}

.experience-img {
    width: 180px;
    height: 230px;
    overflow: hidden;
    border-radius: 10px;
    position: absolute;
    top: 55%;
    left: 60%;
    /* Slightly larger box shadow */
}

.experience-img img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.figma {
    position: absolute;
    /* left: 4%;*/
    top: 27%;
    right: 80%;
}

.html {
    position: absolute;
    left: 5%;
    bottom: 12%;
    width: 60px;
}

.xd {
    position: absolute;
    top: 40%;
    right: 18%;
}

.java {
    position: absolute;
    top: 10%;
    right: 18%;
}

img {
    width: 50px;
}

.black-man img {
    display: block;
    object-fit: cover;
    object-position: -50px 0px;
    width: 100%;
    height: 100%;
}

.features {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    gap: 5%;
    margin-top: 0;
    background: #fff;
    margin-bottom: 140px;
}

.feature-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    position: relative;
    width: 220px;
}

.card-two {
    margin-top: 5%;
}

.card-three {
    margin-top: 10%;
}

.titles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.feature-img {
    width: 220px;
    height: 150px;
    overflow: hidden;
    border-radius: 10px;
}

.feature-img img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.first-img {
    width: 270px;
    height: 350px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow:
        0 8px 12px rgba(0, 0, 0, 0.4),
        0 3px 6px rgba(0, 0, 0, 0.1);
    /* Slightly larger box shadow */
    /* Modern box shadow */
    z-index: 9;
}

.students {
    width: 800px;
    height: 300px;
    overflow: hidden;
    z-index: 7;
}

.students img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.first-img img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.web-icon {
    position: absolute;
    width: 120px;
    height: 90px;
    left: -15%;
    bottom: 30%;
}

.glass-guy {
    position: absolute;
    width: 100px;
    height: 70px;
    right: -20%;
    top: 15%;
}

.pc-girls img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.pc-girls {
    position: absolute;
    width: 80px;
    height: 50px;
    left: 5%;
    top: -10%;
}

.glass-guy img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.facebook-icon {
    position: absolute;
    width: 120px;
    height: 95px;
    left: -8%;
    top: -15%;
}

.facebook-icon img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.google-icon {
    position: absolute;
    width: 120px;
    height: 95px;
    left: 20%;
    bottom: 26%;
}

.paypal-icon {
    position: absolute;
    width: 120px;
    height: 95px;
    right: -9%;
    top: -5%;
}

.steps-section {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blue-container {
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #3789ff;
    gap: 40px;
    position: relative;
}

.dots {
    background: black;
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

.first-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
    gap: 40px;
}

.second-container {
    display: flex;
    align-items: flex-end;
    align-items: center;
    gap: 40px;
    width: 60%;
}

.first-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 45%;
    gap: 10px;
}

.first-content button {
    border-radius: 5px;
    background: #ffba49;
}

.white {
    color: #fff;
    font-size: 30px;
}

.white-dots {
    position: absolute;
    left: 15%;
    top: 8%;
}

.white-dots-two {
    position: absolute;
    right: 13%;
    transform: rotate(90deg);
    bottom: 0;
}

.white-circle-one {
    width: 40px;
    height: 40px;
    border: 3px solid #fff;
    border-radius: 50%;
    background-color: transparent;
    left: 12%;
    top: 5%;
    position: absolute;
}

.white-circle-two {
    width: 200px;
    height: 200px;
    border: 3px solid #fff;
    border-radius: 50%;
    background-color: transparent;
    left: 14%;
    top: 8%;
    position: absolute;
}

.white-circle-three {
    width: 100px;
    height: 100px;
    border: 3px solid #fff;
    border-radius: 50%;
    background-color: transparent;
    right: 17%;
    bottom: 27%;
    position: absolute;
}

.white-circle-four {
    width: 40px;
    height: 40px;
    border: 3px solid #fff;
    border-radius: 50%;
    background-color: transparent;
    right: 27%;
    bottom: 45%;
    position: absolute;
    z-index: 10;
}

.wordpress-icon {
    right: -5%;
    top: -15%;
    position: absolute;
    width: 300px;
}

.hero-wordpress {
    color: #fff;
}

.talent-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-top: 10%;
    width: 100%;
    height: fit-content;
    position: relative;
    background: linear-gradient(180deg, rgba(236, 240, 253, 0) 0%, rgba(236, 240, 253, 0.53) 14.32%, #ecf0fd 45.83%, rgba(236, 240, 253, 0.43) 84.33%, rgba(236, 240, 253, 0) 100%);
}

.stack {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.first-panel {
    color: #94a2b3;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.5px;
    width: 390px;
    height: auto;
    border-radius: 20px;
    background: #fff;

    /* Box-shadow */
    box-shadow: 0px 18px 52.85372px 0px rgba(215, 228, 249, 0.5);
    padding: 25px 45px;
    z-index: 15;
}

.second-panel {
    width: 370px;

    height: 100px;
    border-radius: 20px;
    background: #fff;
    left: 2.5%;
    bottom: -8%;

    /* Box-shadow */
    box-shadow: 0px 18px 52.85372px 0px rgba(215, 228, 249, 0.5);
    padding: 25px 45px;
    position: absolute;
    z-index: 3;
}

.title-talent {
    font-size: 28px;
    width: 400px;
}

.third-panel {
    width: 350px;

    height: 100px;
    border-radius: 20px;
    background: #fff;
    left: 5%;
    bottom: -15%;
    /* Box-shadow */

    padding: 25px 45px;
    position: absolute;
    z-index: 2;
    box-shadow: 0px 18px 52.85372px 0px rgba(215, 228, 249, 0.5);
}

.blue-dots {
    position: absolute;
    left: 30%;
    top: 40%;
    transform: rotate(90deg);
}

.blue-dots-two {
    position: absolute;
    right: 30%;
    top: -4%;
    transform: rotate(90deg);
}

.last-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: auto;
    margin-top: 15%;
    position: relative;
}

.dots-right {
    position: absolute;
    right: 8%;
    top: 38%;
    transform: rotate(90deg);
    overflow: hidden;
}

.dots-left {
    position: absolute;
    left: -6%;
    top: 39%;
    transform: rotate(90deg);
}

.last-title {
    width: 500px;
}

.guy-one {
    position: absolute;
    left: 30%;
    top: 10%;
    width: 60px;
}

.guy-two {
    position: absolute;
    left: 20%;
    top: 60%;
}

.guy-three {
    position: absolute;
    left: 21%;
    top: 120%;
    width: 60px;
}

.guy-five {
    position: absolute;
    right: 30%;
    top: 8%;
    width: 90px;
}

.guy-four {
    position: absolute;
    right: 25%;
    top: 110%;
}

.guy-six {
    position: absolute;
    right: 17%;
    top: 50%;
    width: 60px;
}

.hero-dots {
    position: absolute;
    left: 0;
    top: -5%;
    transform: rotate(90deg);
}

.hero-dots-two {
    position: absolute;
    right: 10%;
    top: 2%;
}

.hero-dots-three {
    position: absolute;
    right: 0;
    top: 12%;
}

.yellow-circle-one {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #ffba49;
    background-color: #fff;
    position: absolute;
    left: 20%;
    top: 25%;
}

.yellow-circle-two {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #ffba49;
    background-color: #fff;
    position: absolute;
    left: 15%;
}

.yellow-circle-three {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #ffba49;
    background-color: #fff;
    position: absolute;
    right: 15%;
}

@media (max-width: 1191px) {
    .left-side {
        width: 60%;
    }

    .right-side {
        width: 40%;
    }
}

@media (max-width: 1120px) {
    .left-side {
        width: 60%;
    }

    .right-side {
        width: 40%;
    }
}

@media (max-width: 1020px) {
    .left-side {
        width: 50%;
    }

    .right-side {
        width: 50%;
    }
}

@media (max-width: 1000px) {
    .left-side {
        width: 40%;
    }

    .right-side {
        width: 60%;
    }
}
</style>

<template>
    <div class="main">
        <div class="w-full flex flex-col lg:grid grid-cols-2 justify-items-stretch gap-y-10 pt-8 mt-8">
            <div class="box">
                <h5 style="color: #ffce39; text-align: left">REQUEST A SERVICE</h5>
                <h1 style="color: white; font-size: 40px; margin: 20px 0px">See go plateform in action</h1>
                <p style="color: white; font-size: 20px; margin-bottom: 20px">Want to take your hiring to the next level? Let’s chat.</p>

                <ul>
                    <li class="list">
                        <span class="font-aws"><i class="fa-regular fa-circle-check"></i></span><span>Improved quality of hire</span>
                    </li>

                    <li class="list">
                        <span class="font-aws"><i class="fa-regular fa-circle-check"></i></span><span>Faster candidate screening</span>
                    </li>

                    <li class="list">
                        <span class="font-aws"><i class="fa-regular fa-circle-check"></i></span><span>Increased hiring diversity</span>
                    </li>

                    <li class="list">
                        <span class="font-aws"><i class="fa-regular fa-circle-check"></i></span><span>Improved candidate experience</span>
                    </li>

                    <li class="list">
                        <span class="font-aws"><i class="fa-regular fa-circle-check"></i></span><span>Lower employee attrition</span>
                    </li>

                    <li class="list">
                        <span class="font-aws"><i class="fa-regular fa-circle-check"></i></span><span>Instant AI-powered grading and ranking</span>
                    </li>
                </ul>

                <div clas="flex  gap-8" style="display: inline-flex">
                    <div class="flex gap-2 items-center" style="display: inline-flex">
                        <img src="@/assets/google_white.svg" style="width: 55px; border-right: 1px solid white" />
                        <span>
                            <i style="color: yellow; font-size: 20px; padding-right: 2px" class="fa-solid fa-star"></i>
                            <i style="color: yellow; font-size: 20px; padding-right: 2px" class="fa-solid fa-star"></i>
                            <i style="color: yellow; font-size: 20px; padding-right: 2px" class="fa-solid fa-star"></i>
                            <i style="color: yellow; font-size: 20px; padding-right: 2px" class="fa-solid fa-star"></i>
                            <i style="color: yellow; font-size: 20px; padding-right: 2px" class="fa-solid fa-star"></i>
                        </span>
                    </div>

                    <div class="flex gap-2 items-center">
                        <img src="https://img.icons8.com/?size=100&id=82471&format=png&color=FFFFFF" style="width: 55px; padding-right: 6px; border-right: 1px solid white" />

                        <span style="">
                            <i style="color: yellow; font-size: 20px; padding-right: 2px" class="fa-solid fa-star"></i>
                            <i style="color: yellow; font-size: 20px; padding-right: 2px" class="fa-solid fa-star"></i>
                            <i style="color: yellow; font-size: 20px; padding-right: 2px" class="fa-solid fa-star"></i>
                            <i style="color: yellow; font-size: 20px; padding-right: 2px" class="fa-solid fa-star"></i>
                            <i style="color: yellow; font-size: 20px; padding-right: 2px" class="fa-solid fa-star"></i>
                        </span>
                    </div>
                </div>
            </div>

            <div class="box2">
                <span>Work Email</span><span class="required">*</span>
                <input name="email" id="email" placeholder="Business Email" type="email" class="hs-input invalid error" inputmode="email" autocomplete="email" value="" />
                <span class="err-email err"></span>

                <div class="w-full flex flex-col lg:grid grid-cols-2 justify-items-stretch gap-10 gap-y-6 mt-2">
                    <div>
                        <span>First Name</span><span class="required">*</span>
                        <input name="first" id="first" placeholder="Frist Name" type="text" class="hs-input invalid error" inputmode="email" autocomplete="email" value="" />
                        <span class="err-fn err"></span>
                    </div>

                    <div>
                        <span>Last Name</span><span class="required">*</span>
                        <input name="last" id="last" placeholder="Last Name" type="text" class="hs-input invalid error" inputmode="email" autocomplete="email" value="" />
                        <span class="err-last err"></span>
                    </div>
                </div>

                <div class="w-full flex flex-col lg:grid grid-cols-2 justify-items-stretch gap-10 gap-y-6 mt-2">
                    <div>
                        <span>Job Title</span><span class="required">*</span>
                        <input name="jbt" id="jbt" placeholder="Job Title" type="text" class="hs-input invalid error" inputmode="email" autocomplete="email" value="" />
                        <span class="err-jbt err"></span>
                    </div>

                    <div>
                        <span>Company Name</span><span class="required">*</span>
                        <input name="cmn" id="cmn" placeholder="Company Name" type="text" class="hs-input invalid error" inputmode="email" autocomplete="email" value="" />
                        <span class="err-cmn err"></span>
                    </div>
                </div>

                <div class="mt-2"><span>Direct Phone number</span><span class="required">*</span></div>
                <p style="color: #6b6b70; font-size: 14px">Please include your country and area code</p>
                <div>
                    <vue-tel-input v-model="tel" id="phone" mode="international"></vue-tel-input>
                    <span class="err-tel err"></span>

                    <div class="mt-2">
                        <span>Country</span><span class="required">*</span>
                        <select v-model="selectedCountry" id="people-select">
                            <option class="option" value="" disabled selected>Please Select</option>
                            <option v-for="(country, index) in countries" :key="index" :value="country">
                                {{ country }}
                            </option>
                        </select>
                        <span class="err-cn err"></span>
                    </div>

                    <div class="mt-2">
                        <span>How many people does your company hire per year?</span>
                        <select v-model="selectedPeople" id="people-select">
                            <option class="option" value="" disabled selected>Please Select</option>
                            <option value="1-10">1-10</option>
                            <option value="11-50">11-50</option>
                            <option value="51-100">51-100</option>
                            <option value="101-500">101-500</option>
                            <option value="500+">500+</option>
                        </select>
                        <span class="err-np err"></span>
                    </div>

                    <div class="mt-2">
                        <span>Number of employees in your company</span><span class="required">*</span>
                        <select v-model="selectedEm" id="people-select">
                            <option class="option" value="" disabled selected>Please Select</option>
                            <option value="1-10">1-10</option>
                            <option value="11-50">11-50</option>
                            <option value="51-100">51-100</option>
                            <option value="101-500">101-500</option>
                            <option value="500+">500+</option>
                        </select>
                        <span class="err-ne err"></span>
                    </div>
                    <p style="margin-top: 34px; color: #6b6b70; font-size: 14px">
                        By submitting your information, you agree to <a href="#">our Terms of Use</a> and <a href="#">Privacy Policy</a>. You can opt-out anytime.
                    </p>

                    <div style="margin-top: 50px">
                        <button class="request-demo" @click="validateForm()">Request Service</button>
                        <br />
                        <span id="sucess" style="color: green"></span>
                    </div>

                    <!--<div class="flex flex-wrap gap-4" style="margin-top:50px; width:100%;">
                <span class="elementor-icon"><i aria-hidden="true" class="far fa-question-circle"></i></span>
                <div>
                    <h6 style="margin-top:inherit !important; margin-bottom:5px; font-weight:bold; font-family: system-ui; font-size:20px">Are you a candidate applying for a role?</h6>
                    <p style="font-family: system-ui;">Find everything you need at<a href="#" style="color:#4258b6; font-weight:bold; text-decoration:none; padding-left:4px">Candidate Support</a> </p>
                </div>
            </div>-->
                </div>
            </div>
        </div>
    </div>

    <div style="margin-top: 50px; margin-bottom: 50px; color: #a3a7a8; text-align: center; font-weight: bold; font-size: 40px">Join these companies already hiring great people</div>
    <div class="flex grid grid-cols-5 flex-wrap gap-10 justify-items-center pt-8" style="padding: 0px 30px; margin-bottom: 50px">
        <img src="@/assets/landing-page-logos/aws.svg" />
        <img src="@/assets/landing-page-logos/pwc.svg" />
        <img src="@/assets/landing-page-logos/deloitte.svg" style="width: 80%" />
        <img src="@/assets/landing-page-logos/kpmg.svg" />
        <img src="@/assets/landing-page-logos/ey.svg" />
    </div>
</template>
<script>
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import axios from "axios";
//import VueCountrySelect from 'vue-country-select';
//import countryList from 'country-list';
export default {
    data() {
        return {
            phone: "",
            country: "",

            selectedCountry: "",
            selectedPeople: "",
            selectedEm: "",
            countries: [
                "Afghanistan",
                "Albania",
                "Algeria",
                "Andorra",
                "Angola",
                "Antigua and Barbuda",
                "Argentina",
                "Armenia",
                "Australia",
                "Austria",
                "Azerbaijan",
                "Bahamas",
                "Bahrain",
                "Bangladesh",
                "Barbados",
                "Belarus",
                "Belgium",
                "Belize",
                "Benin",
                "Bhutan",
                "Bolivia",
                "Bosnia and Herzegovina",
                "Botswana",
                "Brazil",
                "Brunei",
                "Bulgaria",
                "Burkina Faso",
                "Burundi",
                "Cabo Verde",
                "Cambodia",
                "Cameroon",
                "Canada",
                "Central African Republic",
                "Chad",
                "Chile",
                "China",
                "Colombia",
                "Comoros",
                "Congo (Congo-Brazzaville)",
                "Costa Rica",
                "Croatia",
                "Cuba",
                "Cyprus",
                "Czechia (Czech Republic)",
                "Democratic Republic of the Congo",
                "Denmark",
                "Djibouti",
                "Dominica",
                "Dominican Republic",
                "Ecuador",
                "Egypt",
                "El Salvador",
                "Equatorial Guinea",
                "Eritrea",
                "Estonia",
                "Eswatini (fmr. Swaziland)",
                "Ethiopia",
                "Fiji",
                "Finland",
                "France",
                "Gabon",
                "Gambia",
                "Georgia",
                "Germany",
                "Ghana",
                "Greece",
                "Grenada",
                "Guatemala",
                "Guinea",
                "Guinea-Bissau",
                "Guyana",
                "Haiti",
                "Honduras",
                "Hungary",
                "Iceland",
                "India",
                "Indonesia",
                "Iran",
                "Iraq",
                "Ireland",
                "Italy",
                "Jamaica",
                "Japan",
                "Jordan",
                "Kazakhstan",
                "Kenya",
                "Kiribati",
                "Kuwait",
                "Kyrgyzstan",
                "Laos",
                "Latvia",
                "Lebanon",
                "Lesotho",
                "Liberia",
                "Libya",
                "Liechtenstein",
                "Lithuania",
                "Luxembourg",
                "Madagascar",
                "Malawi",
                "Malaysia",
                "Maldives",
                "Mali",
                "Malta",
                "Marshall Islands",
                "Mauritania",
                "Mauritius",
                "Mexico",
                "Micronesia",
                "Moldova",
                "Monaco",
                "Mongolia",
                "Montenegro",
                "Morocco",
                "Mozambique",
                "Myanmar (formerly Burma)",
                "Namibia",
                "Nauru",
                "Nepal",
                "Netherlands",
                "New Zealand",
                "Nicaragua",
                "Niger",
                "Nigeria",
                "North Korea",
                "North Macedonia",
                "Norway",
                "Oman",
                "Pakistan",
                "Palau",
                "Palestine State",
                "Panama",
                "Papua New Guinea",
                "Paraguay",
                "Peru",
                "Philippines",
                "Poland",
                "Portugal",
                "Qatar",
                "Romania",
                "Russia",
                "Rwanda",
                "Saint Kitts and Nevis",
                "Saint Lucia",
                "Saint Vincent and the Grenadines",
                "Samoa",
                "San Marino",
                "Sao Tome and Principe",
                "Saudi Arabia",
                "Senegal",
                "Serbia",
                "Seychelles",
                "Sierra Leone",
                "Singapore",
                "Slovakia",
                "Slovenia",
                "Solomon Islands",
                "Somalia",
                "South Africa",
                "South Korea",
                "South Sudan",
                "Spain",
                "Sri Lanka",
                "Sudan",
                "Suriname",
                "Sweden",
                "Switzerland",
                "Syria",
                "Tajikistan",
                "Tanzania",
                "Thailand",
                "Timor-Leste",
                "Togo",
                "Tonga",
                "Trinidad and Tobago",
                "Tunisia",
                "Turkey",
                "Turkmenistan",
                "Tuvalu",
                "Uganda",
                "Ukraine",
                "United Arab Emirates",
                "United Kingdom",
                "United States of America",
                "Uruguay",
                "Uzbekistan",
                "Vanuatu",
                "Vatican City",
                "Venezuela",
                "Vietnam",
                "Yemen",
                "Zambia",
                "Zimbabwe",
            ],
            err: false,
        };
    },
    components: {
        VueTelInput,
        //VueCountrySelect
    },
    methods: {
        validateForm() {
            //init
            this.err = false;
            document.querySelector(".err-email").textContent = "";
            document.querySelector(".err-fn").textContent = "";
            document.querySelector(".err-last").textContent = "";
            document.querySelector(".err-jbt").textContent = "";
            document.querySelector(".err-cmn").textContent = "";
            document.querySelector(".err-cn").textContent = "";
            document.querySelector(".err-np").textContent = "";
            document.querySelector(".err-ne").textContent = "";
            document.querySelector(".err-tel").textContent = "";
            document.querySelector("#sucess").textContent = "";
            //email
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            let email = document.getElementById("email").value;
            if (email == "") {
                document.querySelector(".err-email").textContent = "Cannot be Empty";
                this.err = true;
            } else {
                if (!emailPattern.test(email)) {
                    document.querySelector(".err-email").textContent = "Please enter a valid email address.";
                    this.err = true;
                }
            }

            //first-name
            let first = document.getElementById("first").value;
            if (first == "") {
                document.querySelector(".err-fn").textContent = "Cannot be Empty";
                this.err = true;
            }

            //last-name
            let last = document.getElementById("last").value;
            if (last == "") {
                document.querySelector(".err-last").textContent = "Cannot be Empty";
                this.err = true;
            }

            //job-title
            let jbt = document.getElementById("jbt").value;
            if (jbt == "") {
                document.querySelector(".err-jbt").textContent = "Cannot be Empty";
                this.err = true;
            }

            //company-name
            let cmn = document.getElementById("cmn").value;
            if (cmn == "") {
                document.querySelector(".err-cmn").textContent = "Cannot be Empty";
                this.err = true;
            }

            //country
            if (this.selectedCountry == "") {
                document.querySelector(".err-cn").textContent = "Cannot be Empty";
                this.err = true;
            }

            //people
            if (this.selectedPeople == "") {
                document.querySelector(".err-np").textContent = "Cannot be Empty";
                this.err = true;
            }

            //employee
            if (this.selectedEm == "") {
                document.querySelector(".err-ne").textContent = "Cannot be Empty";
                this.err = true;
            }

            //tel
            var tel2 = document.querySelector(".vti__input").value.split(" ").join("");
            const phoneNumberRegex = /^\+(\d{1,4})[\s-]?(\d{7,15})$/;
            if (tel2 == "") {
                document.querySelector(".err-tel").textContent = "Cannot be empty";
                this.err = true;
            } else {
                if (!phoneNumberRegex.test(tel2)) {
                    document.querySelector(".err-tel").textContent = "Invalid Phone Number";
                    this.err = true;
                }
                //alert(parseInt(this.tel));
            }
            //alert(parseInt(this.tel));
            if (this.err == false) {
                let contactForm = {
                    email: email,
                    last: last,
                    first: first,
                    jbt: jbt,
                    cmn: cmn,
                    phone: tel2,
                    country: this.selectedCountry,
                    np: this.selectedPeople,
                    ne: this.selectedEm,
                };
                axios
                    .post("https://server.go-platform.com/request-service/request", contactForm, {
                        withCredentials: true,
                    })
                    .then((res) => {
                        console.log(res);
                        document.querySelector("#sucess").textContent = "Email send succesfully";
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }
        },
        /* getAllCountries() {
  return countryList.getData();
},*/
    },
    created() {
        // this.countries = countryList.getData();
    },
};
</script>

<style lang="scss" scoped>
.err {
    color: red;
    font-size: 14px;
}

.main {
    width: 100%;

    background: linear-gradient(90deg, rgb(123, 42, 255) 0%, rgb(0, 174, 240) 100%);

    margin-top: 122px;
    font-weight: bold;
    padding: 10px 50px;
    text-align: left;
}

.list {
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 700;
}

.box2 {
    background-color: white;
    border-radius: 12px;
    width: 100%;
    height: fit-content;
    padding: 40px;
    width: 88%;
}

input {
    box-sizing: border-box;
    padding: 0 15px;
    min-height: 27px;
    width: 100%;
    max-width: 100%;
    display: inline-block;
    height: 40px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    color: #33475b;
    border: 1px solid #cbd6e2;
    border-radius: 3px;
}

.hs-input:hover {
    border-color: #c87872;
}

.required {
    color: red;
    font-size: 20px;
    padding-left: 5px;
}

span {
    font-weight: normal;
}

.box {
    margin-bottom: 50px;
}

.font-aws {
    padding-right: 10px;
}

.vti__input {
    border: none;
    border-radius: 0 2px 2px 0;
    width: 100%;
    outline: none;
    padding-left: 7px;
    height: 40px;
}

#people-select {
    border: 1px solid #bbb;
    height: 40px;
    width: 100%;
    display: inline-block;
    padding: 5px;
    box-sizing: border-box;
    color: black;
}

.option {
    color: #9ca3af;
}

a {
    color: #01253f;
    cursor: pointer;
    text-decoration: underline;
}

.request-demo {
    color: white;
    padding: 20px 30px;
    font-size: 23px;
    font-weight: 400;
    line-height: 16px;
    background: linear-gradient(90deg, rgb(123, 42, 255) 0%, rgb(0, 174, 240) 100%);
    border-radius: 5px;
}

.fa-regular {
    color: white;
}

.fa-question-circle {
    font-weight: bold;
    font-size: 60px;
}

.elementor-icon {
    fill: #0c58b6;
    color: #0c58b6;
}
</style>

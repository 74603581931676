<template>
    <main class="w-full px-10 py-40 flex flex-col justify-start items-center bg-[#fff]" style="background-color: #f8f8fa">
        <div class="w-full p-6 flex flex-col justify-between gap-8" style="text-align: left">
            <div class="flex justify-between items-center">
                <h1 class="w-full font-semibold text-2xl text-slate-700">
                    {{ $t("Measure your candidates on job skills, personality and fit") }}
                </h1>
                <div class="relative flex gap-4 shadow-md">
                    <input type="text" v-model="search" placeholder="Search by Job position" class="border border-slate-300 rounded-md px-4 py-2" style="width: 300px" @input="handleInput" />
                    <div class="absolute border border-slate-300 right-0 top-0 bg-[#F8F8FA] rounded-md h-full w-[40px] flex items-center justify-center">
                        <font-awesome-icon :icon="['fas', 'magnifying-glass']" class="text-[20px]" />
                    </div>
                    <div v-if="this.search.length > 0" class="absolute top-[100%] left-0 w-full h-[300px] overflow-y-scroll bg-[#fff] shadow-md z-[10]">
                        <div class="flex items-center justify-center h-full" v-if="loadingSearch">
                            <LoadingComponent />
                        </div>
                        <div v-else>
                            <div
                                v-for="(job, index) in searchedJobs"
                                :key="index"
                                class="px-4 py-2 hover:bg-[#c2c5c6] duration-300 hover:text-[#334155] cursor-pointer"
                                @click="navigateToPreview(job.title)"
                            >
                                <h2 class="font-semibold text-[16px]">{{ job.title }}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr style="background-color: #334155; height: 2px; width: 100%" />

            <div class="flex flex-wrap gap-8" style="width: 100%">
                <!-- <h2 class="font-semibold text-2" style="color: #0c58b6">All</h2> -->
                <h2
                    v-for="(category, index) in categories"
                    :key="index"
                    :style="{ color: category == this.category ? '#0c58b6' : '' }"
                    @click="
                        () => {
                            this.$router.push(`/assessments-library/${category}`);
                        }
                    "
                    class="font-semibold text-2"
                >
                    {{ category }}
                </h2>
                <!-- <h2 class="font-semibold text-2">Personality and Culture</h2>
                <h2 class="font-semibold text-2">Congtivite ability</h2>
                <h2 class="font-semibold text-2">Languages</h2>
                <h2 class="font-semibold text-2">Accounting and Finance</h2>
                <h2 class="font-semibold text-2">Customer Service</h2>
                <h2 class="font-semibold text-2">Admin and Office</h2>
                <h2 class="font-semibold text-2">General Skills</h2>
                <h2 class="font-semibold text-2">Human Resources</h2>
                <h2 class="font-semibold text-2">Marketing</h2>
                <h2 class="font-semibold text-2">Product</h2>
                <h2 class="font-semibold text-2">Sales</h2>
                <h2 class="font-semibold text-2">Software Development</h2> -->
            </div>
        </div>

        <div v-if="loading" style="width: 100%; height: 500px; display: flex; justify-content: center; align-items: center">
            <LoadingComponent />
        </div>
        <div v-else class="w-full flex flex-col lg:grid grid-cols-3 justify-items-stretch gap-y-10 pt-8 mt-8">
            <div v-for="(test, index) in fthtests" :key="test.title" style="border-radius: 20px; width: 100%; height: 301px; padding: 20px; position: relative">
                <div class="overlay">
                    <p style="position: absolute; top: 30px; font-weight: bold; font-size: 20px; left: 20px; color: #c1c1c1">
                        {{ test.title }}
                    </p>

                    <p
                        style="
                            position: absolute;
                            top: 50px;
                            left: 20px;
                            word-wrap: break-word;
                            width: 100%;
                            font-weight: bold;
                            font-size: 16px;
                            color: white;
                            width: 200px;
                            margin-top: 20px;
                            text-align: left;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3; /* Number of lines before truncating */
                            -webkit-box-orient: vertical;
                            height: 71px;
                        "
                    >
                        {{ test.description }}
                    </p>

                    <a
                        href="/"
                        class="font-light text-base py-6 border border-slate-300"
                        style="position: absolute; bottom: 20px; left: 20px; background-color: white; font-size: 16px; padding: 12px 20px"
                        >View assessment</a
                    >
                </div>
                <div :class="`top-assesment${index}`" style=""></div>
            </div>
        </div>

        <div class="flex flex-wrap gap-4" style="width: 100%">
            <h2 class="font-semibold text-2" style="color: black; padding: 10px 20px">Popular skills:</h2>
            <h2 class="font-semibold text-3 text-[14px]">Programming</h2>
            <h2 class="font-semibold text-3 text-[14px]">Personality and Culture</h2>
            <h2 class="font-semibold text-3 text-[14px]">Congtivite ability</h2>
            <h2 class="font-semibold text-3 text-[14px]">Languages</h2>
            <h2 class="font-semibold text-3 text-[14px]">Accounting and Finance</h2>
            <h2 class="font-semibold text-3 text-[14px]">Customer Service</h2>
            <h2 class="font-semibold text-3 text-[14px]">Admin and Office</h2>
            <h2 class="font-semibold text-3 text-[14px]">General Skills</h2>
            <h2 class="font-semibold text-3 text-[14px]">Human Resources</h2>
            <h2 class="font-semibold text-3 text-[14px]">Marketing</h2>
            <h2 class="font-semibold text-3 text-[14px]">Product</h2>
            <h2 class="font-semibold text-3 text-[14px]">Sales</h2>
            <h2 class="font-semibold text-3 text-[14px]">Software Development</h2>
        </div>

        <div v-if="loading" style="width: 100%; height: 500px; display: flex; justify-content: center; align-items: center"></div>
        <div style="width: 100%" v-else>
            <!-- <div style="padding-left: 20px; color: #292a2d; font-size: 25px; text-align: left; width: 100%; margin-top: 20px; font-weight: bold">Popular Assesments</div>

            <div class="w-full flex flex-col lg:grid grid-cols-4 mt-4">
                <TestCard v-for="test in tests" :key="test.title" :name="test.title" :preview="test.description" :duration="test.duration" :tag="test.tag" />
            </div> -->
            <div style="padding-left: 20px; color: #292a2d; font-size: 25px; text-align: left; width: 100%; margin-top: 20px; font-weight: bold">Assesments</div>

            <div class="w-full flex flex-col lg:grid grid-cols-4 mt-4">
                <TestCard v-for="test in tests" :key="test.title" :name="test.title" :preview="test.description" :duration="test.duration" :tag="test.tag" :recommendations="test.recommendations" />
            </div>
            <div class="my-[5%] flex justify-evenly items-center border-t py-3 w-full">
                <button
                    class="border rounded-md shadow-card px-4 py-2 hover:shadow-none duration-300"
                    @click="prevPage"
                    :disabled="currentPage === 1"
                    :style="{ cursor: currentPage == 1 ? 'not-allowed' : '' }"
                >
                    Previous
                </button>
                <div>
                    <!-- <span>Page {{ currentPage }} of {{ totalPages }}</span> -->
                    <span v-if="currentPage > 1" class="cursor-pointer border rounded-md shadow-card px-4 py-2 mx-2 hover:shadow-none duration-300" @click="changePage(currentPage - 1)">
                        {{ currentPage - 1 }}
                    </span>
                    <span class="border rounded-md shadow-card hover:shadow-none duration-300 cursor-pointer px-4 py-2 mx-2 text-[#fff] bg-[#0c58b6]" @click="changePage(currentPage)">
                        {{ currentPage }}
                    </span>
                    <span v-if="currentPage < totalPages" class="cursor-pointer border hover:shadow-none duration-300 rounded-md shadow-card px-4 py-2 mx-2" @click="changePage(currentPage + 1)">
                        {{ parseInt(currentPage) + 1 }}
                    </span>
                    <span
                        v-if="currentPage < parseInt(totalPages) - 1 && currentPage == 1"
                        class="border rounded-md shadow-card px-4 py-2 mx-2 cursor-pointer hover:shadow-none duration-300"
                        @click="changePage(currentPage + 2)"
                    >
                        {{ parseInt(currentPage) + 2 }}
                    </span>
                    <!-- <span
                        v-for="(page, index) in Math.min(totalPages, 3)"
                        :key="index"
                        class="border rounded-md shadow-card px-4 py-2 mx-2"
                        @click="changePage(page)"
                        :style="{ backgroundColor: currentPage == page ? '#0c58b6' : '', color: currentPage == page ? '#fff' : '' }"
                    >
                        {{ parseInt(page) }}
                    </span> -->
                    <span v-if="totalPages > 3 && currentPage < parseInt(totalPages) - 1">...</span>
                    <!-- v-for="(page, index) in (Math.max(1, totalPages - 2), totalPages)" -->
                    <!-- :key="index" -->
                    <span
                        class="border rounded-md shadow-card px-4 py-2 mx-2 cursor-pointer hover:shadow-none duration-300"
                        @click="changePage(totalPages)"
                        :style="{ backgroundColor: currentPage == page ? '#0c58b6' : '', color: currentPage == page ? '#fff' : '' }"
                        v-if="currentPage < parseInt(totalPages) - 1"
                    >
                        {{ totalPages }}
                    </span>
                </div>
                <button
                    class="border rounded-md shadow-card px-4 py-2 hover:shadow-none duration-300"
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                    :style="{ cursor: currentPage == totalPages ? 'not-allowed' : '' }"
                >
                    Next
                </button>
            </div>
        </div>
    </main>
</template>

<script>
import TestCard from "@/components/TestCard.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import axios from "axios";
import { debounce } from "lodash";

export default {
    name: "AssessmentLibrary",
    components: { TestCard, LoadingComponent },
    data() {
        return {
            category: "All",
            search: "",
            searchedJobs: [],
            loading: true,
            loadingSearch: true,
            // displayedTests: [],
            tests: [],
            fthtests: [],
            options: ["Cognitive ability", "Language", "Personallity & culture", "Programming skills", "Role specific skills", "Situational judgement", "Software skills", "Typing speed"],
            categories: [
                "All",
                "Information Technology",
                "Software Development",
                // "Healthcare and Medical Services",
                "Sales and Marketing",
                "Education and Training",
                "Finance and Accounting",
                "Engineering",
                "Customer Service and Support",
                "Human Resources",
                "Manufacturing and Production",
                "Management and Leadership",
            ],
            currentPage: 1,
            totalPages: 1,
            limit: 20, // Number of items per page
        };
    },
    computed: {
        displayedTests() {
            return this.tests.filter((test) => {
                if (this.search) {
                    return test.category.includes(this.search);
                } else return this.category === "all" ? true : test.category === this.category;
            });
        },
    },
    methods: {
        getPositions() {
            this.loading = true;
            axios
                //https://server.go-platform.com/
                .get(`https://server.go-platform.com/jobsPages/jobs`, {
                    params: {
                        category: this.category,
                        page: this.currentPage,
                        limit: this.limit,
                    },
                })
                .then((res) => {
                    //alert(res.data);
                    this.tests = res.data.jobPositions;
                    this.totalPages = res.data.totalPages; // Assuming totalCount is provided in response
                    this.fthtests = this.tests.slice(0, 3);
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.$router.push({ query: { page: this.currentPage } });
                this.getPositions();
                window.scrollTo({ top: 500, behavior: "smooth" }); // Smooth scroll to the top of the page
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.$router.push({ query: { page: this.currentPage } });
                this.getPositions();
                window.scrollTo({ top: 500, behavior: "smooth" }); // Smooth scroll to the top of the page
            }
        },
        changePage(page) {
            this.currentPage = page;
            this.$router.push({ query: { page: this.currentPage } });
            this.getPositions();
            window.scrollTo({ top: 500, behavior: "smooth" }); // Smooth scroll to the top of the page
        },
        handleInput() {
            this.loadingSearch = true;
            this.debouncedSearchJob();
        },
        searchJob() {
            // Your search logic here
            this.loadingSearch = false;
            axios
                .get(`https://server.go-platform.com/jobsPages/searchJob`, {
                    params: {
                        search: this.search,
                    },
                })
                .then((response) => {
                    this.searchedJobs = response.data.jobPositions;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        navigateToPreview(jobPosition) {
            this.$router.push({
                path: "/job-position-preview",
                query: { jobPosition: jobPosition },
            });
        },
    },
    created() {
        this.debouncedSearchJob = debounce(this.searchJob, 2000);
    },
    watch: {
        category() {
            // this.filterTests();
            this.currentPage = 1;
            this.getPositions();
        },
        "$route.params.cat"(newVal) {
            this.category = newVal;
            // You can also perform any other actions here when params.cat changes
            this.getPositions();
        },
        "$route.query.page"() {
            // this.category = newVal;
            // You can also perform any other actions here when params.cat changes
            this.getPositions();
        },
    },

    async mounted() {
        this.category = this.$route.params.cat;
        this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
        /*  const headers = {
            "Content-Type": "application/json",
            withCredentials: true,
        };*/
        await axios
            //https://server.go-platform.com/
            .get(`https://server.go-platform.com/jobsPages/jobs`, {
                params: {
                    category: this.category,
                    page: this.currentPage,
                    limit: this.limit,
                },
            })
            .then((res) => {
                //alert(res.data);
                this.tests = res.data.jobPositions;
                this.totalPages = res.data.totalPages;
                this.loading = false;
            })
            .catch((error) => {
                console.log(error);
                this.loading = false;
            });
    },
};
</script>

<style lang="scss" scoped>
.container {
    max-width: 100%;
}

select::-ms-expand {
    display: none;
    color: red;
    background: green;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.top-assesment0 {
    background-image: url("@/assets/2-guys.png");
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.text-2 {
    color: #c2c5c6;
    cursor: pointer;
    font-size: 14px;
}

.text-3 {
    color: #111416;
    cursor: pointer;
    font-size: 14px;
    background-color: white;
    padding: 10px 20px;
    border-radius: 24px;
    border: 1px solid #eceeee;
}

.text-2:hover {
    color: #3d3f41;
    text-decoration: underline;
}

.top-assesment1 {
    background-image: url("@/assets/glass-guy.png");
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.top-assesment2 {
    background-image: url("@/assets/our_company3.jpg");
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.overlay {
    position: absolute;
    border-radius: 12px;
    top: 20px;
    left: 20px;
    width: 93%;
    height: 87%;
    background: linear-gradient(to right, rgba(11, 12, 12, 0.9) 1%, rgba(255, 255, 255, 0) 99%);
    z-index: 2;
}

.introjs-tooltip {
    min-width: 400px;
    max-width: 400px;
}
</style>

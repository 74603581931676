<template>
    <PlanningContact :showEmail="showEmail" :toggleEmailWindow="toggleEmailWindow" :selectedPlan="selectedPlan" />
    <div class="pricing-container w-full">
        <section class="pricing-panel">
            <div class="header">
                <h1>{{ $t("Streamline your Recruitment. Start free.") }}</h1>
                <h4>{{ $t("Choose the perfect plan for your business needs") }}</h4>
            </div>
            <!-- <div class="saving-container">
                <div class="save-span">
                    <span>{{ $t("Save 15%") }}</span> {{ $t("on monthly plan!") }}
                </div>
                <div class="time-container">
                    <button class="month" :class="Plan === 'month' ? 'before:translate-x-[0] text-white' : 'before:translate-x-[100%] text-[#a1a1aa]'" @click="SelectPlan('month')">
                        {{ $t("Monthly") }}
                    </button>
                    <button class="year" :class="Plan === 'year' ? ' text-white' : ' text-[#a1a1aa]'" @click="SelectPlan('year')">{{ $t("Yearly") }}</button>
                </div>
            </div> -->
            <div class="pricing-cards-wrapper">
                <div class="pricing-card">
                    <div class="pricing-header">
                        <div class="pricing-type">
                            <div class="h2">{{ $t("Free") }}</div>
                            <div class="h5">{{ $t("Best for personal use") }}</div>
                        </div>
                        <div class="price-value"><span>$0</span>{{ $t("/month") }}</div>
                    </div>
                    <button class="pricing-card-button" @click="() => this.$router.push('/register')">{{ $t("Get Started") }}</button>
                    <div class="pricing-features">
                        <div class="h5">{{ $t("What you get:") }}</div>
                        <div class="infos-list">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                {{ $t("Skills Access: Essential skills only") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                {{ $t("AI Anti-Cheat System") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                {{ $t("Result Interpretation & Reporting") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Skills Suggestion
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Admin Users: 1
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Skills per Candidate: 5
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Candidate Limit: Unlimited
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pricing-card">
                    <div class="pricing-header">
                        <div class="pricing-type">
                            <div class="h2">{{ $t("Pay per use ") }}</div>
                            <div class="h5">{{ $t("Best for Small Business") }}</div>
                        </div>
                        <div class="price-value">
                            <span>${{ Plan === "year" ? 21 : 21 }}</span
                            >{{ $t("/3 candidates") }}
                        </div>
                    </div>
                    <!-- @click="toggleEmailWindow(`Basic - ${Plan === 'year' ? 'yearly' : 'Mounthly'}`)" -->
                    <button class="pricing-card-button" @click="navigateToRegister(`Basic - ${Plan === 'year' ? 'yearly' : 'Mounthly'}`)">{{ $t("Get Started") }}</button>
                    <div class="pricing-features">
                        <div class="h5">{{ $t("Upgraded features:") }}</div>
                        <div class="infos-list">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Hard skills assessments
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Soft skills assessments
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Custom questions
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Screening Questions
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                AI Anti-Cheat System
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Skills suggestion
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Result interpretation
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Admin Users: 3
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Skills per Assessment: 5
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Assessment Limit: Create unlimited
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Pricing: $21 per 3 candidates
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pricing-card">
                    <div class="pricing-header">
                        <div class="pricing-type">
                            <div class="h2">{{ $t("Subscription") }}</div>
                            <div class="h5">{{ $t("Best for personal use") }}</div>
                        </div>
                        <div class="price-value">
                            <span>{{ $t("Custom") }}</span>
                        </div>
                    </div>
                    <!-- @click="toggleEmailWindow(`Enterprise - ${Plan === 'year' ? 'yearly' : 'Mounthly'}`)" -->
                    <button class="pricing-card-button" @click="() => this.$router.push('/contact')">{{ $t("Contact Us") }}</button>
                    <div class="pricing-features">
                        <div class="h5">{{ $t("Unlimited features:") }}</div>
                        <div class="infos-list">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Skills Access: All skills
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Custom questions
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Qualifycational questions
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                AI Anti-Cheat System
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Skills suggestion
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Result interpretation
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Admin Users: 10
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Candidate Limit: Unlimited
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Assessment Limit: Create unlimited
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Skills per Assessment: Customizable
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                Custom Assessments
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="learn-more">
                <h1 @click="showMore" v-if="!isShown">
                    <span>{{ $t("Click here") }}</span> {{ $t("To Compare Plans") }}
                </h1>
            </div> -->
        </section>
        <!-- <section v-if="isShown" class="feature-table">
            <div class="header-container">
                <div class="header header-two">
                    <h1>{{ $t("Feature Table") }}</h1>
                    <h4>{{ $t("Choose the perfect plan for your business needs") }}</h4>
                </div>
                <div class="saving-container">
                    <div class="save-span">
                        <span>{{ $t("Save 15%") }}</span> {{ $t("on monthly plan!") }}
                    </div>
                    <div class="time-container">
                        <button class="monthly">{{ $t("Monthly") }}</button>
                        <button class="yearly">{{ $t("Yearly") }}</button>
                    </div>
                </div>
            </div>
            <div class="cards-features">
                <div class="blank-container"></div>
                <div class="small-wrapper">
                    <div class="small-cards">
                        <div class="pricing-header">
                            <div class="pricing-type">
                                <div class="h2">{{ $t("Free") }}</div>
                            </div>
                            <div class="price-value"><span>$0</span>{{ $t("/month") }}</div>
                        </div>
                        <button class="pricing-card-button">{{ $t("Get Started") }}</button>
                    </div>
                    <div class="small-cards">
                        <div class="pricing-header">
                            <div class="pricing-type">
                                <div class="h2">{{ $t("Basic") }}</div>
                            </div>
                            <div class="price-value"><span>$99</span>{{ $t("/month") }}</div>
                        </div>
                        <button class="pricing-card-button">{{ $t("Get Started") }}</button>
                    </div>
                    <div class="small-cards">
                        <div class="pricing-header">
                            <div class="pricing-type">
                                <div class="h2">{{ $t("Pro") }}</div>
                            </div>
                            <div class="price-value"><span>$295</span>{{ $t("/month") }}</div>
                        </div>
                        <button class="pricing-card-button">{{ $t("Get Started") }}</button>
                    </div>
                    <div class="small-cards">
                        <div class="pricing-header">
                            <div class="pricing-type">
                                <div class="h2">{{ $t("Enterprise") }}</div>
                            </div>
                            <div class="price-value">
                                <span>{{ $t("Custom") }}</span>
                            </div>
                        </div>
                        <button class="pricing-card-button button-enterprise">
                            {{ $t("Get Started") }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="table-container">
                <div class="tables table-one">
                    <div class="header-row">{{ $t("Evaluation") }}</div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Users") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">1</div>
                        <div class="third-column column">3</div>
                        <div class="fourth-column column">5</div>
                        <div class="fifth-column column">{{ $t("Unlimited") }}</div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Assessment") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">10</div>
                        <div class="third-column column">50</div>
                        <div class="fourth-column column">300</div>
                        <div class="fifth-column column">{{ $t("Unlimited") }}</div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Tests Per Assessment") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">5</div>
                        <div class="third-column column">5</div>
                        <div class="fourth-column column">5</div>
                        <div class="fifth-column column">5</div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Assessment Library") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Coding Languages") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">Limited</div>
                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="tables table-two">
                    <div class="header-row">Flow</div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Automation") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>
                        <div class="third-column column">-</div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Reporting And Analytics") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Talent Marketplace") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>
                        <div class="third-column column">-</div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Customer Support") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>

                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="tables table-three">
                    <div class="header-row">{{ $t("Adds on") }}</div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Calendar Integration") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>

                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Assessment templates based on job roles") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>
                        <div class="third-column column">-</div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Corporate Assessments") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>
                        <div class="third-column column">-</div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="tables table-four">
                    <div class="header-row">{{ $t("Anti-Cheat Measures") }}</div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Time Limits on Tests") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>

                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("IP Address Logging") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>

                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Copy-Paste Disabled") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>

                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Webcam Snapshots") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">-</div>

                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="tables table-five">
                    <div class="header-row">{{ $t("Quality Control") }}</div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("GDPR Compliance") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>

                        <div class="second-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row text-row">
                        <div class="first-column column">
                            {{ $t("Webcam Snapshots") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">
                            {{ $t("15 step") }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="first-column column">
                            {{ $t("Copy-Paste Disabled") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>

                        <div class="second-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="third-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fourth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                        <div class="fifth-column column">
                            <img loading="lazy" decoding="async" src="@/assets/checkmark-2.svg" alt="" />
                        </div>
                    </div>
                    <div class="row text-row">
                        <div class="first-column column">
                            {{ $t("Webcam Snapshots") }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                                    fill="#CECECE"
                                />
                            </svg>
                        </div>
                        <div class="second-column column">
                            {{ $t("To maintain test integrity") }}
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
    </div>
</template>

<script>
import PlanningContact from "@/components/PlanningContact";

export default {
    name: "PlanningView",
    components: {
        PlanningContact,
    },
    data() {
        return {
            isShown: false,
            Plan: "month",
            showEmail: false,
            selectedPlan: "",
        };
    },
    methods: {
        showMore() {
            this.isShown = true;
        },
        SelectPlan(plan) {
            this.Plan = plan;
        },
        toggleEmailWindow(selectedPlan) {
            this.selectedPlan = selectedPlan;
            this.showEmail = !this.showEmail;
        },
        navigateToRegister(plan) {
            this.$router.push({
                path: "/register",
                query: { plan: plan },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.feature-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 7%;
    width: 75%;
    @media (max-width: 767.98px) {
        display: none;
    }
}

.pricing-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 10%;
    @media (max-width: 767.98px) {
        padding-top: 4rem;
    }
}

.pricing-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 80%;
}

.header-two {
    text-align: start;
    width: 75%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
}

.header-two h4 {
    text-align: start;
}

.header {
    text-align: center;
}

.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.header h1 {
    color: var(--sementics-color-fg-default, #18181b);
    /* sementic.type/title/xl */
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 20%;
    @media (max-width: 767.98px) {
        line-height: 40px;
    }
    /* 36.8px */
}

.header h4 {
    color: var(--sementics-color-fg-default, #18181b);
    /* sementic.type/body/xl */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 20px;

    /* 22.5px */
}

.feature-table .saving-container {
    width: 85%;
    display: flex;
    align-items: center;
    align-items: flex-end;
    margin-bottom: 20px;
}

.pricing-panel .saving-container {
    width: 85%;
    display: flex;
    align-items: center;
    align-items: flex-end;
    margin-bottom: 20px;
}

.feature-table .saving-container {
    width: 85%;
    display: flex;
    align-items: center;
    align-items: flex-end;
    margin-bottom: 20px;
}

.pricing-card {
    button {
        border: none;
        cursor: pointer;
        transition: 0.3s;
        color: white;
    }
}

button:hover {
    opacity: 0.8;
}

.time-container {
    display: inline-flex;
    padding: 4px;
    align-items: flex-start;
    border-radius: 100px;
    border: 1px solid var(--sementics-color-border-muted, #e4e4e7);
    background: var(--sementics-color-primary-on-default, #fff);
    margin-left: 10px;
}

.month {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: transparent;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    position: relative;
    z-index: 5;
    width: 50%;
    /* 16.1px */
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100px;
        background: #2196f3;
        left: 0;
        top: 0;
        z-index: -1;
        transition: all 500ms ease-out;
    }
}

.year {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: transparent;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    z-index: 5;
    width: 50%;
    /* 16.1px */
}

.save-span {
    color: #2196f3;
    /* sementic.type/label/s */
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
}

.save-span span {
    color: #2196f3;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    /* 15px */
}

.learn-more {
    margin-top: 3%;
    @media (max-width: 767.98px) {
        display: none;
    }
}

.learn-more h1 {
    color: #000;
    /* sementic.type/title/xl */
    font-family: Roboto;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 20%;
    text-align: start;
    cursor: pointer;
    @media (max-width: 767.98px) {
        line-height: 40px;
    }

    &:hover span {
        text-decoration: underline;
        color: #2196f3;
    }
}

.learn-more h1 span {
    color: #000;
    /* sementic.type/title/xl */
    font-family: Roboto;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 20%;
    text-align: start;
}

.price-value {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/body/s */
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    /* 13.8px */
}

.price-value span {
    color: var(--sementics-color-fg-default, #18181b);

    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    /* 36.8px */
}

.pricing-cards-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    @media (max-width: 767.98px) {
        flex-direction: column;
        .pricing-card {
            width: 90%;
            margin: 0 auto;
        }
    }
}

.pricing-cards-wrapper button {
    background: #2196f3;
}

.blank-container {
    width: 25%;
}

.small-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: 75%;
}

.small-wrapper button {
    background: #2196f3;
}

.cards-features {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.pricing-card {
    display: flex;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--sementics-color-border-muted, #e4e4e7);
    background: var(--sementics-color-bg-surface, #fff);
    width: 20%;
}

.small-cards {
    display: flex;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--sementics-color-border-muted, #e4e4e7);
    background: var(--sementics-color-bg-surface, #fff);
    width: 25%;
    height: auto;
}

.pro {
    border: solid 2px #2196f3;
    position: relative;
    border-radius: 8px;
}

.popular-span svg {
    margin-left: 5px;
}

.popular-span {
    position: absolute;
    width: 100%;
    background: #2196f3;
    border: solid 2px #2196f3;
    border-radius: 5px 5px 0px 0px;

    color: #fff;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.pricing-card-button {
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--sementics-color-primary-default, #2563eb);
}

.button-enterprise {
    background: #fff !important;
    color: #2196f3;
    border: 1px solid #2196f3;
}

.pricing-header {
    display: flex;
    padding: 0px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.pricing-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.pricing-type .h2 {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/title/l */
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    /* 23px */
}

.pricing-type .h5 {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/body/m */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: start;

    /* 17.5px */
}

.pricing-value {
    display: flex;
    align-items: flex-end;
}

.pricing-features {
    display: flex;
    padding: 0px 16px 16px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    height: auto;
    width: 100%;
}

.pricing-features .h5 {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/label/l */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    text-align: start;
    width: 100%;
    /* 18.4px */
}

.infos-list {
    list-style-type: none;
    align-items: flex-start;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.infos-list div {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/body/m */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    width: 100%;
    /* 17.5px */
}

svg {
    margin-right: 10px;
}

.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 98%;
    margin-top: 30px;
}

.tables {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px 10px;
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/body/l */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    border-bottom: 1px solid var(--sementics-color-border-disabled, #d4d4d8);
}

.header-row {
    border-radius: 8px;
    background: var(--sementics-color-bg-subtle, #e4e4e7);
    width: 100%;
    padding: 12px 10px;
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/title/l */
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
    /* 23px */
}

.column {
    width: 20%;
    text-align: center;
}

.column img {
    margin-left: 50%;
    transform: translateX(-50%);
}

.first-column {
    text-align: start;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.second-column {
    color: #71717a;
}

.third-column {
    color: #71717a;
}

.fourth-column {
    color: #71717a;
}

.fifth-column {
    color: #2563eb;
}

.table-container img {
    background: #2563eb;
    border-radius: 50%;
    padding: 2px;
}

.text-row .first-column {
    width: 20%;
}

.text-row .second-column {
    width: 80%;
}

@media (max-width: 1322px) {
    .pricing-header {
        height: 114px;
    }

    .pricing-type {
        height: 49px;
    }
}

@media (max-width: 1253px) {
    .small-cards {
        gap: 10px;
        display: flex;
        padding: 24px 16px 16px 16px;
        flex-direction: column;
        align-items: flex-start;

        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--sementics-color-border-muted, #e4e4e7);
        background: var(--sementics-color-bg-surface, #fff);
        width: 30%;
        height: auto;
    }

    .blank-container {
        display: none;
    }

    .small-wrapper {
        width: 100%;
    }
}
</style>
